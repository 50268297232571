export default [
  {
    key: "thumbnailId",
    initKey: "thumbnail",
    label: "field.thumbnail",
    type: "async-single-image",
    path: "gallery",
    pw: 1900,
    ph: 1271,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,

  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text"
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    label: "breadcrumb.englishCategoryContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    type: "textarea",
    cols: 12
  },

  {
    label: "breadcrumb.khmerCategoryContent",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    type: "textarea",
    cols: 12
  },

  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
]
